<template>
    <div class="flex flex-col main-section w-full h-full my-6">
        <span class="block mb-1 text-bold text-lg">Debugging Vue Select</span>
        <div class="block w-full h-full px-5 py-10">
            <div class="block w-1/2 px-4 py-3">
                <FormKit
                    type="form"
                    v-model="selected"
                >
                    <!-- FormKit <input> -->
                    <FormKit
                        type="vs"
                        label="Selection"
                        name="vue_select_example"
                        help="This is an example Vue Select input."
                        :taggable="true"
                        :pushTags="true"
                        :clearable="false"
                        value="Example Value"
                        validation="required"
                        validation-visibility="live"
                        :debug="false"
                        :onOptionSelected="
                            (event, value, node) => {
                                node.input(value);
                            }
                        "
                    />
                </FormKit>
                <pre
                    wrap
                    class="bg-gray-200 p-4 text-xs"
                    >{{ selected }}</pre
                >
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Select',
        setup(props, context) {
            const selected = ref({});
            return {
                selected,
            };
        },
    });
</script>
