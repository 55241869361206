<template>
    <div class="flex main-section">
        <div
            id="content"
            class="relative min-h-screen min-w-full"
        >
            <div class="absolute right-0 -top-20">
                <div class="flex flex-row space-x-2">
                    <button
                        id="trigger"
                        class="p-2 border-2 border-primary-50 bg-white hover:bg-gray-300"
                        @click="sendToast"
                    >
                        Raise
                    </button>
                    <button
                        id="trigger"
                        class="p-2 border-2 border-primary-50 bg-white hover:bg-gray-300"
                        @click="dismissRandomToast"
                    >
                        Dismiss
                    </button>
                    <button
                        id="trigger"
                        class="p-2 border-2 border-primary-50 bg-white hover:bg-gray-300"
                        @click="clearAllToasts"
                    >
                        Clear All
                    </button>
                </div>
            </div>
            <ToastOverlay
                :toasts="toasts"
                @dismiss="handleDismissal"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, onMounted } from 'vue';
    import { promiseTimeout } from '@vueuse/shared';

    // <!-- COMPONENTS -->
    import ToastOverlay from '@/components/toasts/ToastOverlay.vue';

    // <!-- UTILITIES -->
    import { ToastIcon } from '@/components/toasts/hooks/useToast.js';

    // <!-- COMPOSABLES -->
    import { useToastOverlay } from '@/components/toasts/hooks/useToastOverlay.js';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Toasts',
        components: {
            ToastOverlay,
        },
        setup() {
            // Prepare avaialble types for debugging.
            /** @type {('default' | keyof ToastIcon)[]} */
            const toastTypes = /** @type {any} */ (Object.keys(ToastIcon));
            toastTypes.unshift('default');

            // Prepare overlay state.
            const overlay = useToastOverlay();

            // Helper.
            const getRandomType = () => {
                const index = Math.floor(Math.random() * toastTypes.length);
                const type = toastTypes[index];
                return type;
            };

            // Send new toast of random type.
            const sendToast = () => {
                const type = getRandomType();
                const toast = overlay.enqueue({
                    id: `ex-${type}-${overlay.queue.value.length}`,
                    type: type,
                    title: `${type}-${overlay.queue.value.length}`,
                    description: 'This is an example toast.',
                    dismissable: true,
                    lifetime: 0, // 2000 + Math.floor(Math.random() * 5000),
                });

                console.log(
                    `[debug] Send new ${type} toast: ${toast.id} (Expires in ${toast.lifetime} ms).`
                );
            };

            // dismiss random toast, if any available.
            const dismissRandomToast = () => {
                const items = overlay.queue.value;

                if (items.length > 0) {
                    const index = Math.floor(Math.random() * items.length);
                    const toast = items[index];
                    const id = toast.id;

                    console.log(`[debug] Dismiss random toast ${id}.`);
                    overlay.dismiss(id, true);
                }
            };

            const clearAllToasts = () => {
                console.log('[debug] Clear all toasts.');
                overlay.clear();
            };

            /**
             * @param {{ event: MouseEvent, id: string, icon: Toasts.Icon, title: string }} event
             */
            const handleDismissal = (event) => {
                console.log(`[debug] Dismiss ${event.id} toast.`);
                overlay.dismiss(event.id, true);
            };

            return {
                toasts: overlay.queue,
                sendToast,
                dismissRandomToast,
                clearAllToasts,
                handleDismissal,
            };
        },
    });
</script>
