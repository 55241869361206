<template>
    <div class="flex flex-col">
        <span class="text-bold text-xl"><h1>Debug Page</h1></span>
        <router-view v-slot="{ Component }">
            <!-- <keep-alive> -->
            <component :is="Component" />
            <!-- </keep-alive> -->
        </router-view>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DebugIndex',
    });
</script>
