<template>
    <div class="flex main-section">
        <div
            id="content"
            class="relative min-h-screen min-w-full"
        >
            <div class="absolute insert-y-10 left-0 ml-10 mt-2">
                <div class="flex flex-row space-x-2">
                    <button
                        id="trigger"
                        class="px-2 py-4 border-2 border-primary-50 bg-white hover:bg-gray-300"
                        @click="raiseAlert"
                    >
                        Raise Alert!
                    </button>
                    <button
                        id="trigger"
                        class="px-2 py-4 border-2 border-primary-50 bg-white hover:bg-gray-300"
                        @click="clearAlerts"
                    >
                        Clear Alerts!
                    </button>
                </div>
            </div>
            <div class="absolute inset-y-10 right-0 mr-10 mt-2 min-w-18">
                <BaseAlert
                    :alerts="alerts.state.alerts.value"
                    @dismiss:alert="alerts.methods.dismissAlert($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import BaseAlert from '@/components/alerts/BaseAlert.vue';

    // <!-- COMPOSABLES -->
    import { useAlerts } from '@/components/alerts/hooks/useAlerts';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'Alerts',
        components: {
            BaseAlert,
        },
        props: {
            debug: {
                type: Boolean,
                default: true,
            },
        },
        setup() {
            const types = ['info', 'warning', 'error', 'success'];

            const alerts = useAlerts();

            const raiseAlert = () => {
                const number = alerts.state.alerts.value.length + 1;
                const index = number % types.length;
                const showMessages = number % 2;
                const makeDismissable = (number + 1) % 2;
                const messages =
                    showMessages == 1
                        ? [
                              'Detail 1',
                              'Detail 2',
                              'Detail 3',
                              'Detail 4',
                              'Detail 5',
                          ].slice(0, number % 4)
                        : [];
                const alert = alerts.methods.createAlert({
                    id: `alert-${number}`,
                    type: types[index],
                    title: `Alert ${number}`,
                    content:
                        messages.length == 0
                            ? `This is an example alert with no messages.`
                            : `This is an alert with ${messages.length} message(s).`,
                    messages: messages,
                    dismissable: makeDismissable == 1,
                });
                alerts.methods.pushAlert(alert);
            };

            const clearAlerts = () => {
                alerts.state.alerts.value = [];
            };

            return { raiseAlert, clearAlerts, alerts };
        },
    });
</script>
